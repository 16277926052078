import Utils from "../utils.js";
import './menu.css';

class Menu extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const root = this.attachShadow({ mode: "open" });
    const hamburger = document.createElement("div");
    this.classList.remove('hidden');
    root.innerHTML = `<slot></slot>`

    hamburger.innerHTML = `
      <div class="menu-hamburger">
          <label class="btn btn-circle swap swap-rotate">

            <!-- this hidden checkbox controls the state -->
            <input id="menu-state" type="checkbox" class="hidden" />

            <!-- hamburger icon -->
            <div class="swap-off menu-close">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
                  </svg>
            </div>

            <!-- close icon -->
            <div class="swap-on menu-open">
              <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
              <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"/></svg>
            </div>

          </label>
      </div>
    <div class="overlay"></div>
    `;
    document.body.appendChild(hamburger);
    this.ui = Utils.uiHandler(
      [
        ".menu-hamburger .swap",
        "click",
        (evt) => this.setOverlay(this.open(evt.currentTarget.children[0].checked))
      ],
      [
        ".menu-box",
        "click",
        (evt) => {
          const target = evt.target;
          evt.preventDefault();
          const targetIsAnchor = target instanceof HTMLAnchorElement && target;
          const parentIsAnchor = target.parentNode instanceof HTMLAnchorElement && target.parentNode;
          const anchor = targetIsAnchor || parentIsAnchor;
          return anchor && !anchor.className.includes("btn-active") && anchor;
        },
        async (anchor) => {
          if (anchor) {
            document.body.dispatchEvent(new CustomEvent('loading-start', { detail: 'menu' }));
            document.querySelectorAll('.opacity-in').forEach((v) => v.classList.add('opacity-out'));
            await this.setOverlay(this.open(false));
            location.href = anchor.href || "/";
          }
        },
      ],
      [
        ".overlay",
        "click",
        () => this.setOverlay(this.open(false)),
      ],
      ["#menu-state"]
    );
    this.dispatchEvent(new CustomEvent("ready"));
  }

  async setOverlay(show) {
    this.ui.overlay.style.visibility = show ? "visible" : "hidden";
    return new Promise((res) => setTimeout(() => res(), 250))
  }

  open(visible) {
    this.ui.menuBox.classList[visible ? "add" : "remove"]("opened");
    this.ui.menuState.checked = visible;
    return visible;
  }

  disconnectedCallback() {
    document.body.querySelector(".menu-hamburger")?.remove();
  }
}

window.customElements.define("ui-menu", Menu);
